// import il8n from '@/assets/language/index.js';
import { fromSearchFore } from '@/const/publicData/record'
// const { t } = il8n.global;

export const sendSearch = [
    ...fromSearchFore,    
{
    type: 'input',
    name: '系列简称',
    prop: 'p1',
},{
    type: 'input',
    name: '状态',
    prop: 'p4',
},{
    type: 'input',
    name: 'Token Id',
    prop: 'tokenId',
},{
    type: 'input',
    name: '合约地址',
    prop: 'contractAddress',
},{
    type: 'datetime',
    name: '创建时间',
    prop: 'createTime',
},]