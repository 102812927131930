import il8n from '@/assets/language/index.js';
const { t } = il8n.global;

export const sendOption = {
    border: true,
    menuAlign: 'center',
    align: 'center',
    height: 'auto',
    refreshBtn: false,
    showClomnuBtn: false,
    searchMenuSpan: 6,
    searchLabelWidth: '130',
    labelWidth: '150',
    columnBtn: false,
    addBtn: false,
    editBtn: false,
    delBtn: false,
    menu:false,
    column: [{
        width: 100,
        label: '商户',
        prop: 'tenantName',
    },{
        width: 100,
        label: 'App名称',
        prop: 'appName',
    }, {
        width: 100,
        label: '链',
        prop: 'chain',
    }, {
        width:100,
        label: '协议',
        prop: 'protocol'
    }, {
        width:150,
        label: '系列简称',
        prop: 'collectionName'
    },{
        width:120,
        label: '钱包名称',
        prop: 'walletName'
    }, {
        width:150,
        label: '总作品发送数量',
        prop: 'batchSum'
    },{
        width:100,
        label: '状态',
        prop: 'batchStatus'
    },{
        width:100,
        label: 'Token Id',
        prop: 'tokenId'
    },{
        width:150,
        label: '合约地址',
        prop: 'contractAddress'
    },{
        label: '创建时间',
        prop: 'applyTime'
    },{
        label: '发送时间',
        prop: 'batchSendTime'
    },{
        width: 140,
        label: t("button.operation"),
        prop: 'caozuo',
        fixed:'right',
    }]
}