<template>
    <div>
        <fromSearch :object_='sendSearch_' @searchFun='getList' />
        <avue-crud :option="sendOption_" :data="page.list" :page="page" :table-loading="listLoading"
            @size-change="sizeChange"
            @current-change="currentChange" >
            <template #menu-left>
                <el-button type="primary" @click="addData">新增</el-button>
            </template>
            <template #batchStatus='scope'>
                {{batchStatus_[scope.row.batchStatus]}}
            </template>
            <template #applyTime="scope">
                {{turnTimeFun(scope.row.applyTime)}}
            </template>
            <template #batchSendTime="scope">
                {{turnTimeFun1(scope.row.batchSendTime)}}
            </template>
            <template #caozuo="scope">
                <el-button text type="primary" size="small" @click="detailViewFun(scope.row)">详情 </el-button>
                <el-button text type="primary" size="small" @click="bindFun(scope.row)">导出 </el-button>
            </template> 
        </avue-crud>  
         <el-dialog v-model="innerVisible1" width="500" :title="textArray[pageStyle]" >
            <addView v-if='pageStyle === 1' ref='addView_' @successFun='successFun_'/>
            <detailView v-else ref='detailView_' />
            <template #footer>
                <div class="dialog-footer">
                    <el-button @click="innerVisible1 = false">{{$t('button.closeQ')}}</el-button>
                    <el-button type="primary" @click="nextFun" v-if='pageStyle === 1'>下一步</el-button>
                </div>
            </template>
        </el-dialog>
    </div>
</template>
<script setup>
    import { sendSearch } from '@/const/from/nft/send'
    import { sendOption } from '@/const/crud/nft/send'
    import { downBlobFile } from '@/utils/util'
    import { nextTick, ref } from 'vue'
    import fromSearch from '@/components/fromSearch.vue'
    import addView from './add'
    import detailView from './detail/index.vue'
    import { turnTimeFun,turnTimeFun1 } from '@/utils/util.js'  

    const sendOption_ = ref(sendOption); 
    const sendSearch_ = ref(sendSearch);
    let textArray = ['','批量发送','详情']

    import mixins from '@/views/mixins/page'
    let pageObj = mixins(['getSendNftList'])
    const {page,listLoading,sizeChange,currentChange,getList} = pageObj
    const innerVisible1 = ref(false)
    const pageStyle = ref(1)
    const detailView_ = ref(null)
    const addView_ = ref(null)
    let batchStatus_ = ['待发送','发送中','发送成功','发送失败','部分成功']

    const addData = ()=>{
        pageStyle.value = 1
        innerVisible1.value = true;
        nextTick(()=>{
            addView_.value.init_()
        })
    }
    const bindFun = (e)=>{
        downBlobFile('/assets/withdrawapply/exportByBatchTxid',"作品发送.xlsx",{
            batchTxid: e.batchTxid
        })
    }
    const detailViewFun = (obj)=>{
        pageStyle.value = 2
        innerVisible1.value = true
        nextTick(()=>{
            detailView_.value.init_({
                batchTxid: obj.batchTxid
            })
        })
    }
    const successFun_ = ()=>{
        getList(1)
        innerVisible1.value = false
    }
    const nextFun = ()=>{
        addView_.value.nextFun()
    }
    getList(1)
</script>

<style lang="scss" scoped>
</style>